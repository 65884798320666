
import { defineComponent } from 'vue';
import useTexts from '../composables/texts';
import NewsCards from '../components/NewsCards.vue';

export default defineComponent({
    name: 'NewsArticles',
    components: { NewsCards },
    setup() {
        const { texts } = useTexts();

        return {
            texts,
        };
    },
});
