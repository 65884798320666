<template>
    <div id="news" class="pt-xxl bg-icons">
        <div class="c w-l">
            <div class="mx-auto text-center mb-xxl w-12 s:w-9 m-up:w-6">
                <h2 class="size-3 weight-black">{{ texts.news_title }}</h2>
                <div class="color-secondary" v-html="texts.news_text" />
            </div>
        </div>
        <div class="c w-xl flex flex-wrap align-stretch py-xxl l-up:pt-0 columns">
            <NewsCards mode="overview" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useTexts from '../composables/texts';
import NewsCards from '../components/NewsCards.vue';

export default defineComponent({
    name: 'NewsArticles',
    components: { NewsCards },
    setup() {
        const { texts } = useTexts();

        return {
            texts,
        };
    },
});
</script>
